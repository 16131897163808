import React from "react";
import circle from "src/assets/images/svg/localization-circle.svg";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";
import typographySizes from "src/assets/styles/typographySizes";
import SubTitle from "src/components/Texts/SubTitle.js";
import styled from "styled-components";
import BodyText from "src/components/Texts/BodyText.js";
import Title from "src/components/Texts/Title.js";

const Wrapper = styled.section`
  width: 100%;
  padding-bottom: 100rem;

  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    padding-bottom: 50rem;
  }
`;

const StyledTitle = styled(Title)`
  margin-bottom: 20rem;
`;

const Big = styled.h2`
  font-size: ${typographySizes.xxl}rem;
  color: ${colors.yellow};
  font-family: "Bebas Neue";
  line-height: 0.9;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.xl}rem;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    font-size: ${typographySizes.m}rem;
  }
`;

const BigText = () => {
  return (
    <Wrapper>
      <StyledTitle
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration={500}
      >
        Bez owijania w&nbsp;bawełnĘ:
      </StyledTitle>
      <Big data-sal="slide-up" data-sal-easing="ease" data-sal-duration={500}>
        bĘdzie pysznie i&nbsp;naturalnie!
      </Big>
    </Wrapper>
  );
};

export default BigText;
