import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";

const SubTitle = styled.h3`
  font-size: ${typographySizes.sm}rem;
  font-weight: 600;
  line-height: 1.3;
  color: ${colors.light};
  letter-spacing: 0.25em;
  text-align: center;
  font-family: "Montserrat", sans-serif;

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
  }
`;

export default SubTitle;
