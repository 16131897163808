import React from "react";
import circle from "src/assets/images/svg/order-circle.svg";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";
import typographySizes from "src/assets/styles/typographySizes";
import SubTitle from "src/components/Texts/SubTitle.js";
import styled from "styled-components";

const Wrapper = styled.section`
  width: 100%;
  padding-bottom: 100rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const TopRowWrapper = styled.div`
  display: flex;
  margin-bottom: 90rem;
  width: 100%;
`;

const Circle = styled.img`
  width: 16.666%;
  :nth-of-type(2) {
    transition-delay: 0.2s;
  }
  :nth-of-type(3) {
    transition-delay: 0.4s;
  }
  :nth-of-type(4) {
    transition-delay: 0.6s;
  }
  :nth-of-type(5) {
    transition-delay: 0.8s;
  }
  :nth-of-type(6) {
    transition-delay: 1s;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 25%;
    :nth-of-type(5) {
      display: none;
    }
    :nth-of-type(6) {
      display: none;
    }
  }
`;

const Title = styled.h2`
  font-size: ${typographySizes.l}rem;
  color: ${colors.light};
  font-weight: 400;
  font-family: "Bebas Neue";
  span {
    color: ${colors.orange};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
  }
`;

const OrderWrapper = styled.div`
  display: flex;
  margin-top: 70rem;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    align-items: center;
  }
`;

const OrderBox = styled.a`
  font-size: ${typographySizes.s}rem;
  color: ${colors.orange};
  font-weight: 600;
  width: calc(20% - 30rem);
  margin-right: 20rem;
  border: solid 1rem ${colors.orange};
  height: 50rem;
  line-height: 50rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.5s;
  position: relative;
  cursor: pointer;
  :last-of-type {
    margin-right: 0rem;
  }
  span {
    display: block;
    transition: all 0.5s;
  }
  :hover {
    border: solid 1rem ${colors.light};
    transition: all 0.5s;
    background-color: ${colors.light};
    color: ${colors.green};
    span {
      transform: translateY(-100%);
      transition: all 0.5s;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-right: 0rem;
    margin-bottom: 30rem;
    :last-of-type {
      margin-bottom: 0rem;
    }
  }
`;

const GarazowaWrapper = styled.div`
  width: calc(20% - 30rem);
  margin-right: 20rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-right: 0rem;
    margin-bottom: 30rem;
  }
`;

const GarazowaBox = styled(OrderBox)`
  width: 100%;
  margin-right: 0rem;
`;

const GarazowaSmallText = styled.p`
  width: 100%;
  font-size: ${typographySizes.s}rem;
  border: solid 1rem ${colors.orange};
  transition: all 0.5s;
  background-color: ${colors.orange};
  color: ${colors.green};
  height: 32rem;
  line-height: 32rem;
  font-weight: 600;
  text-align: center;
`;
const Order = () => {
  let circleDuration = 300;
  return (
    <Wrapper>
      <TopRowWrapper>
        <Circle
          src={circle}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={circleDuration}
        />
        <Circle
          src={circle}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={circleDuration}
        />
        <Circle
          src={circle}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={circleDuration}
        />
        <Circle
          src={circle}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={circleDuration}
        />
        <Circle
          src={circle}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={circleDuration}
        />
        <Circle
          src={circle}
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={circleDuration}
        />
      </TopRowWrapper>
      <Title data-sal="slide-up" data-sal-easing="ease" data-sal-duration="500">
        <span>•</span> ZAMÓW ONLINE <span>•</span>
      </Title>
      <SubTitle
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        Nie możesz nas odwiedzić? <br /> zamów coś na wynos
      </SubTitle>
      <OrderWrapper>
        {/* <OrderBox
          href="https://food.bolt.eu/pl-pl"
          target="_blank"
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        >
          <span>BOLT FOOD</span>
          <span>ZAMÓW</span>
        </OrderBox> */}
        <GarazowaWrapper
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        >
          <GarazowaBox href="tel:+48 577 185 265">
            <span>ZAMÓW U NAS</span>
            <span>+48 577 185 265</span>
          </GarazowaBox>
          {/* <GarazowaSmallText>Najlepsza oferta!</GarazowaSmallText> */}
        </GarazowaWrapper>

        {/* <OrderBox
          href=""
          target="_blank"
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        >
          <span>UBER</span>
          <span>ZAMÓW</span>
        </OrderBox> */}
      </OrderWrapper>
    </Wrapper>
  );
};

export default Order;
