import React from "react";

import styled, { keyframes } from "styled-components";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery";
import MenuCircle from "src/assets/images/svg/menu-circle.inline.svg";
import smallCircle from "src/assets/images/svg/small-circle.svg";
import BodyText from "src/components/Texts/BodyText.js";
import menu from "src/static/Menu.pdf";

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  /* justify-content: space-between; */

  padding: 50rem 0 150rem 0;
  @media (max-width: ${mediaQuery.tablet}) {
    /* flex-direction: column; */
    flex-wrap: wrap;
    padding: 50rem 0;
  }
`;

const StyledBodyText = styled(BodyText)`
  width: 35%;
  margin-right: 5%;
  flex-shrink: 0;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 55%;
    margin-right: 5%;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 20rem;
  }
`;

const MenuLink = styled.a`
  font-size: ${typographySizes.xxl}rem;
  color: ${colors.yellow};
  font-family: "Bebas Neue";
  cursor: pointer;
  margin-right: 10%;
  transition: all 0.5s;
  line-height: 0.9;
  @media (max-width: ${mediaQuery.tablet}) {
    order: 2;
    font-size: 58vw;
    position: relative;
    left: -15rem;
    margin-top: 10rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    left: -5rem;
    font-size: 56vw;
  }
  :hover {
    transition: all 0.5s;
    color: ${colors.orange};
  }
`;

const CirclesWrapper = styled.div`
  display: flex;
  margin-top: 10rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 0rem;
  }

  @media (max-width: ${mediaQuery.mobile}) {
    order: 4;
  }
`;

const move = keyframes`

0% {
    transform: translateX(-130%);
  }
  25% {
    transform: translateX(0);
  }


  
  75% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(130%);
  }
`;
const CircleWrapper = styled.div`
  overflow: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    order: 1;
  }
`;

const MenuCircle1 = styled(MenuCircle)`
  animation: ${move} 2s ease infinite;
`;

const MenuCircle2 = styled(MenuCircle)`
  animation: ${move} 2s 0.2s ease infinite;
`;

const MenuCircle3 = styled(MenuCircle)`
  animation: ${move} 2s 0.4s ease infinite;
  #Path_46 {
    fill: ${colors.orange};
  }
`;

const Menu = () => {
  return (
    <>
      <img
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration={500}
        src={smallCircle}
      />
      <Wrapper>
        <StyledBodyText
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        >
          Spotkania, rozmowy i przecinające się szlaki kulinarne. To miejsce,
          gdzie każdy znajdzie coś dla siebie, zaczynając od lokalnych
          autorskich fantazji z lampką wina, a kończąc na dalekich kulinarnych
          wyprawach.
          <br />
          <br />
          Sprawdź aktualne menu:
        </StyledBodyText>
        <MenuLink
          href={menu}
          target="_blank"
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        >
          MENU
        </MenuLink>
        <CirclesWrapper
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        >
          <CircleWrapper>
            <MenuCircle1 />
          </CircleWrapper>
          <CircleWrapper>
            <MenuCircle2 />
          </CircleWrapper>
          <CircleWrapper>
            <MenuCircle3 />
          </CircleWrapper>
        </CirclesWrapper>
      </Wrapper>
    </>
  );
};

export default Menu;
