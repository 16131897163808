import React from "react";

import styled, { keyframes } from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery";
import Instagram from "src/assets/images/svg/instagram-icon.inline.svg";
import circle from "src/assets/images/svg/lunch-circle.svg";
import Title from "src/components/Texts/Title.js";
import BodyText from "src/components/Texts/BodyText.js";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { StaticImage } from "gatsby-plugin-image";

const Wrapper = styled.section`
  width: 100%;
  display: flex;

  padding: 120rem 0;
  position: relative;
  align-items: center;
  ${Title} {
    margin-top: 120rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding: 40rem 10rem;
    width: calc(100% - 20rem);
  }
`;

const FirstCol = styled.div`
  width: 50%;
  position: relative;
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const StyledBodyText = styled(BodyText)`
  width: 60%;
  margin-top: 20rem;
  margin-bottom: 120rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const InstagamText = styled(BodyText)`
  margin-bottom: 20rem;
  width: 60%;
  color: ${colors.yellow};
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;
const ImgCol = styled.div`
  width: 50%;

  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-right: 0%;
  }
`;

const ImgWrapper = styled.div`
  border: solid 5rem ${colors.yellow};
`;

const Lunch = () => {
  let intViewportWidth = 0;
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    intViewportWidth = window.innerWidth;
  }
  let mobile = isBrowser && intViewportWidth < 900;
  return (
    <ParallaxProvider>
      <Wrapper>
        <FirstCol>
          <img
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
            src={circle}
          />
          <Title
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            WYSKOCZ NA <br /> pyszny lunch
          </Title>
          <StyledBodyText
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Biuro, praca, dom… Nie masz czasu na jedzenie. Przyjdź do nas na
            pyszny lunch! Daj sobie kilka minut wytchnienia.
            <br /> <br />
            Menu lunchowe składa się z zupy, dania głównego i deseru. Taki
            zestaw da Ci energię na resztę dnia!
            <br /> <br />
            Serwujemy od poniedziałku do piątku w godzinach 12-15.
          </StyledBodyText>
          <InstagamText
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Menu lunchowe publikujemy codziennie na naszym instagramie.
          </InstagamText>
          <a
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
            target="_blank"
            href="https://www.instagram.com/garazowa_5a/"
          >
            <Instagram />
          </a>
        </FirstCol>
        <ImgCol>
          <Parallax y={[30, -30]} disabled={mobile}>
            <ImgWrapper>
              <StaticImage
                src="../../assets/images/image/lunch.png"
                alt="Garazowa"
              />
            </ImgWrapper>
          </Parallax>
        </ImgCol>
      </Wrapper>
    </ParallaxProvider>
  );
};

export default Lunch;
