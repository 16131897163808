const typographySizes = {
  xs: 10,
  s: 13,
  sm: 20,
  m: 50,
  l: 90,
  xl: 100,
  xxl: 200,
};

export default typographySizes;
