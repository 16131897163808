import React, { useState } from "react";

import styled, { keyframes } from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery";
import SmallCircle from "src/assets/images/svg/about-smallcircle.inline.svg";
import BigCircle from "src/assets/images/svg/about-bigcircle.inline.svg";

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  margin-top: 60rem;
  align-items: flex-start;
`;

const StyledBigCircle = styled(BigCircle)`
  width: 83.33%;
  #Path_45 {
    fill: ${(props) => props.col};
  }
`;

const StyledSmallCircle = styled(SmallCircle)`
  width: 8.33%;
  #Path_42 {
    fill: ${(props) => props.col};
  }
`;

const HoverIlustation = () => {
  const [bigCircle, setBigCircle] = useState(true);
  const [smallCircle1, setSmallCircle1] = useState(true);
  const [smallCircle2, setSmallCircle2] = useState(true);

  return (
    <Wrapper>
      <StyledSmallCircle
        onMouseEnter={() => {
          setSmallCircle1(smallCircle1 ? false : true);
        }}
        col={smallCircle1 ? colors.yellow : colors.light}
      />
      <StyledSmallCircle
        onMouseEnter={() => {
          setSmallCircle2(smallCircle2 ? false : true);
        }}
        col={smallCircle2 ? colors.orange : colors.yellow}
      />
      <StyledBigCircle
        onMouseEnter={() => {
          setBigCircle(bigCircle ? false : true);
        }}
        col={bigCircle ? colors.light : colors.orange}
      />
    </Wrapper>
  );
};

export default HoverIlustation;
