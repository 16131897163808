import React from "react";

import styled, { keyframes } from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";
import Circle from "src/assets/images/svg/wine-circle.inline.svg";
import Title from "src/components/Texts/Title.js";
import BodyText from "src/components/Texts/BodyText.js";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  /* padding: 110rem 0; */
  padding-bottom: 160rem;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding: 50rem 0;
  }
`;

const ImgCol = styled.div`
  width: 35%;
  margin-right: 5%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-right: 0%;
  }
`;

const ImgWrapper = styled.div`
  border: solid 5rem ${colors.yellow};
`;

const FirstCol = styled.div`
  width: 35%;
  margin-top: 100rem;
  margin-right: 5%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-right: 0%;
    margin-top: 50rem;
  }
`;

const SecCol = styled.div`
  margin-top: 100rem;
  width: 20%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 50rem;
  }
`;

const FirstParagraph = styled(BodyText)`
  color: ${colors.light};
  margin-bottom: 130rem;
  margin-top: 5rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50rem;
  }
`;

const SecParagraph = styled(BodyText)`
  color: ${colors.light};
`;

const SmallText = styled(BodyText)`
  color: ${colors.orange};
  margin-top: 95rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 0rem;
  }
`;

const CircleWrapper = styled.div`
  width: 100%;
  margin-top: 70rem;
`;

const move = keyframes`

0% {
  opacity: 0;
  }

  
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const Circle1 = styled(Circle)`
  animation: ${move} 2s ease infinite;
`;
const Circle2 = styled(Circle)`
  animation: ${move} 2s 0.2s ease infinite;
`;
const Circle3 = styled(Circle)`
  animation: ${move} 2s 0.4s ease infinite;
`;

const Wine = () => {
  let intViewportWidth = 0;
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    intViewportWidth = window.innerWidth;
  }
  let mobile = isBrowser && intViewportWidth < 900;
  return (
    <ParallaxProvider>
      <Wrapper>
        <ImgCol>
          <Parallax y={[30, -30]} disabled={mobile}>
            <ImgWrapper>
              <StaticImage
                src="../../assets/images/image/wine.png"
                alt="Garazowa"
              />
            </ImgWrapper>
          </Parallax>
        </ImgCol>

        <FirstCol>
          <Title
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            MOŻE <br />
            LAMPKĘ WINA?
          </Title>
          <FirstParagraph
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Jesteśmy miłośnikami wina i możemy Wam zagwarantować, że takich
            odmian jeszcze nie próbowaliście!
          </FirstParagraph>
          <SecParagraph
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Też lubisz eksperymentować i spędzać godziny na odkrywaniu nowych
            winnych smaków?
            <br /> <br />
            Jesteśmy gotowi na wyzwanie i nowe odkrycia!
          </SecParagraph>
        </FirstCol>
        <SecCol>
          <SmallText
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Zaskoczymy Was!
          </SmallText>
          <CircleWrapper>
            <Circle1
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration={500}
            />
            <Circle2
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration={500}
            />
            <Circle3
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration={500}
            />
          </CircleWrapper>
        </SecCol>
      </Wrapper>
    </ParallaxProvider>
  );
};

export default Wine;
