const distances = {
  s: 20,
  gap: 30,
  pageMargin: 150,
  barW: 12,
  mFromTop: 120,
  mBottomPage: 120,
};

export default distances;
