import React from "react";
import circle from "src/assets/images/svg/order-circle.svg";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";
import typographySizes from "src/assets/styles/typographySizes";
import SubTitle from "src/components/Texts/SubTitle.js";
import styled from "styled-components";
import BodyText from "src/components/Texts/BodyText.js";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { StaticImage } from "gatsby-plugin-image";
import Title from "src/components/Texts/Title.js";
import imgCircle from "assets/images/svg/takeaway-circle.svg";
import lokalnieZdrowo from "assets/images/svg/lokalnie-zdrowo.svg";

const Wrapper = styled.section`
  width: 100%;
  padding-bottom: 100rem;
  display: flex;
  position: relative;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding-bottom: 0rem;
  }
`;

const ImgCol = styled.div`
  width: 50%;

  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-right: 0%;
  }
`;

const ImgWrapper = styled.div`
  border: solid 5rem ${colors.yellow};
`;

const InfoCol = styled.div`
  width: 40%;
  margin-top: 30rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const StyledTitle = styled(Title)`
  margin-top: 20rem;
`;

const StyledBodyText = styled(BodyText)`
  width: 80%;
  margin-top: 10rem;
`;

const LokalnieZdrowo = styled.img`
  float: right;
  transform: translateY(-50%) rotate(90deg);
  opacity: 0;
  width: 20%;
  &.sal-animate {
    opacity: 1;

    transform: translateY(-50%) rotate(10deg);
  }
`;

const Events = () => {
  let intViewportWidth = 0;
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    intViewportWidth = window.innerWidth;
  }
  let mobile = isBrowser && intViewportWidth < 900;
  return (
    <ParallaxProvider>
      <Wrapper>
        <ImgCol>
          <Parallax y={[30, -30]} disabled={mobile}>
            <ImgWrapper>
              <StaticImage
                src="../../assets/images/image/takeaway.png"
                alt="Garazowa"
              />
            </ImgWrapper>
          </Parallax>
        </ImgCol>
        <InfoCol>
          <img
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
            src={imgCircle}
          />
          <StyledTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Szef Kuchni <br /> na wynos
          </StyledTitle>
          <StyledBodyText
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Jak już zostałeś miłośnikiem naszej kuchni to zabierz ją ze sobą do
            domu, podaruj komuś w prezencie albo weź na wakacje.
            <br /> <br />
            Spiżarka jest stale dopieszczana i urozmaicana przez naszego szefa
            kuchni.
          </StyledBodyText>
          <LokalnieZdrowo
            data-sal
            data-sal-duration={1500}
            src={lokalnieZdrowo}
          />
        </InfoCol>
      </Wrapper>
    </ParallaxProvider>
  );
};

export default Events;
