import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";

const Title = styled.h2`
  font-size: ${typographySizes.m}rem;
  font-weight: 400;
  line-height: 0.9;
  color: ${colors.orange};
  font-family: "Bebas Neue";

  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.m}rem;
  }
`;

export default Title;
