import React from "react";

import CookieConsent from "react-cookie-consent";
import typographySizes from "src/assets/styles/typographySizes.js";
import styled from "styled-components";

import colors from "src/assets/styles/colors.js";
import BodyText from "src/components/Texts/BodyText.js";

import politykaPrywatności from "src/static/PolitykaPrywatnosci.pdf";

const Link = styled.a`
  font-size: ${typographySizes.s}rem;
  font-family: "Montserrat", sans-serif;
  color: ${colors.orange};
  font-weight: inherit;
  text-decoration: underline;
`;

const Text = styled(BodyText)`
  color: ${colors.orange};
  font-weight: 600;
`;

const Cookies = () => (
  <CookieConsent
    style={{
      background: `${colors.light}`,
      padding: "0px",
      fontSize: `${typographySizes.s}rem`,
      fontFamily: "Montserrat",
    }}
    buttonStyle={{
      background: `${colors.orange}`,
      margin: "5px 0px 5px 15px",
      fontSize: `${typographySizes.s}rem`,
      fontFamily: "Montserrat",
      color: `${colors.light}`,
      fontWeight: "600",
    }}
    declineButtonStyle={{
      background: `transparent`,
      margin: "5px 15px 5px 5px",
      color: `${colors.green}`,
      textDecoration: "underline",
      fontSize: `${typographySizes.s}rem`,
      fontFamily: "Montserrat",
      fontWeight: "600",
    }}
    contentStyle={{ margin: "5px 15px", fontSize: `${typographySizes.s}rem` }}
    location="bottom"
    buttonText="Akceptuje"
    enableDeclineButton
    flipButtons
    declineButtonText="Nie akcpetuje"
    cookieName="atsby-gdpr-google-tagmanager"
  >
    <Text>
      Używamy plików cookie.{" "}
      <Link href={politykaPrywatności} target="blank">
        Zobacz, w jakim celu.
      </Link>
    </Text>
  </CookieConsent>
);

export default Cookies;
