import React from "react";

import styled, { keyframes } from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery";
import logoSvg from "src/assets/images/svg/garazowa-logo.svg";
import circle from "src/assets/images/svg/header-circle.svg";
import BodyText from "src/components/Texts/BodyText.js";
import Div100vh from "react-div-100vh";

const Wrapper = styled(Div100vh)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const MidWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  color: ${colors.light};
  font-size: 7vw;

  font-family: "Bebas Neue";
  text-align: center;
  span {
    color: ${colors.orange};
    font-size: inherit;
    font-family: inherit;
  }
`;

const SubTitle = styled.h2`
  font-size: 18rem;
  font-weight: 600;
  line-height: 1.5;
  color: ${colors.light};
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
  }
`;

const Logo = styled.img`
  padding-top: 100rem;
`;

const BottomWrapper = styled.div`
  padding-bottom: 60rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CirclesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 25rem 0;
`;

const move = keyframes`

0% {
    transform: translateY(-130%);
  }
  25% {
    transform: translateY(0);
  }


  
  75% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(130%);
  }
`;

const CircleWrapper = styled.div`
  overflow: hidden;

  img {
    animation: ${move} 2s ease infinite;
  }
  :nth-last-of-type(2) {
    img {
      animation: ${move} 2s 0.2s ease infinite;
    }
  }
`;

const Header = () => {
  return (
    <Wrapper>
      <Logo
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration={500}
        data-sal-delay={0}
        src={logoSvg}
        alt="garazowa log"
      />
      <MidWrapper>
        <Title
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
          data-sal-delay={200}
        >
          <span> • </span>przyjaciele • Wino • Jedzenie <span>•</span>
        </Title>
        <SubTitle
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
          data-sal-delay={400}
        >
          TO nasz IDEALNY przepis
        </SubTitle>
      </MidWrapper>
      <BottomWrapper>
        <BodyText
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
          data-sal-delay={600}
        >
          głodny? zapraszamy!
        </BodyText>
        <CirclesWrapper
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
          data-sal-delay={800}
        >
          <CircleWrapper>
            <img src={circle} />
          </CircleWrapper>
          <CircleWrapper>
            <img src={circle} />
          </CircleWrapper>
        </CirclesWrapper>
        <BodyText
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
          data-sal-delay={1000}
        >
          {" "}
          NDZ–CZW: 12–21:30 • PT SOB: 12–23
        </BodyText>
      </BottomWrapper>
    </Wrapper>
  );
};

export default Header;
