import React from "react";
import circle from "src/assets/images/svg/localization-circle.svg";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";
import typographySizes from "src/assets/styles/typographySizes";
import SubTitle from "src/components/Texts/SubTitle.js";
import styled from "styled-components";
import BodyText from "src/components/Texts/BodyText.js";
import Title from "src/components/Texts/Title.js";

const Wrapper = styled.section`
  width: 100%;
  padding-bottom: 125rem;
  display: flex;
  justify-content: space-between;

  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding-bottom: 50rem;
  }
`;

const StyledTitle = styled(Title)`
  margin-bottom: 20rem;
`;

const InfoCol = styled.div`
  width: 30%;
  a {
    font-weight: 600;
    font-size: inherit;
    color: inherit;
    transition: all 0.5s;

    :hover {
      color: ${colors.orange};
      transition: all 0.5s;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const CirclesImg = styled.img`
  width: calc(70% - 30rem);
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 50rem;
  }
`;

const Localization = () => {
  return (
    <Wrapper>
      <InfoCol>
        <StyledTitle
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        >
          JAK DO NAS <br />
          TRAFIĆ
        </StyledTitle>
        <BodyText
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        >
          Po najlepszym zapachu na mokotowie :) <br /> lub wpisując w nawigacji
          nasz adres:
          <br />
          <br />
          <a href="https://goo.gl/maps/cGR1hM7zE6RK9syN9" target="_blank">
            {" "}
            ul. Garażowa 5a
          </a>
        </BodyText>
      </InfoCol>
      <CirclesImg
        data-sal="slide-up"
        data-sal-easing="ease"
        data-sal-duration={500}
        src={circle}
      />
    </Wrapper>
  );
};

export default Localization;
