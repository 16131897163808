import React from "react";

import styled, { keyframes } from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery";
import logoSvg from "src/assets/images/svg/garazowa-logo.svg";
import smallCircle from "src/assets/images/svg/small-circle.svg";
import Title from "src/components/Texts/Title.js";
import BodyText from "src/components/Texts/BodyText.js";
import HoverIlustation from "src/components/About/HoverIlustation.js";

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 110rem 0;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding: 50rem 0;
  }
`;

const FirstCol = styled.div`
  width: 30%;
  ${Title} {
    max-width: 300rem;
    margin-bottom: 80rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 20rem;
  }
`;

const SecCol = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${BodyText} {
    width: 50%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const StyledBodyText = styled(BodyText)`
  width: 50%;
`;

const SmallText = styled(BodyText)`
  max-width: 140rem;
  color: ${colors.yellow};
  text-align: right;
`;

const About = () => {
  return (
    <Wrapper>
      <FirstCol>
        <Title
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        >
          Spotkania, rozmowy i&nbsp;przecinajĄce siĘ szlaki kulinarne.
        </Title>
        <img
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
          src={smallCircle}
          alt="circle"
        />
      </FirstCol>
      <SecCol>
        <StyledBodyText
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        >
          Restauracja Garażowa 5a powstała z&nbsp;połączenia pasji do gotowania,
          chęci dzielenia się własnymi doświadczeniami i&nbsp;dreszczyku emocji
          towarzyszącemu poznawaniu nowych smaków i&nbsp;ludzi.{" "}
        </StyledBodyText>
        <SmallText
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        >
          Krótko o naszej restauracji
        </SmallText>
        <HoverIlustation
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        ></HoverIlustation>
      </SecCol>
    </Wrapper>
  );
};

export default About;
