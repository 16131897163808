import React from "react";
import colors from "src/assets/styles/colors";
import distances from "src/assets/styles/distances";

import mediaQuery from "src/assets/styles/mediaQuery";
import typographySizes from "src/assets/styles/typographySizes";
import SubTitle from "src/components/Texts/SubTitle.js";
import styled from "styled-components";
import BodyText from "src/components/Texts/BodyText.js";
import Title from "src/components/Texts/Title.js";
import sygnet from "src/assets/images/svg/footer-sygnet.svg";
import logo from "src/assets/images/svg/footer-logo.svg";
import fb from "src/assets/images/svg/footer-fb.svg";
import instagram from "src/assets/images/svg/footer-instagram.svg";

const FooterWrapper = styled.footer`
  background-color: ${colors.orange};
  padding: 90rem 0 70rem;
`;

const Wrapper = styled.div`
  padding: 0 ${distances.pageMargin}rem;

  @media (max-width: ${mediaQuery.laptop}) {
    padding: 0 ${distances.pageMargin / 2}rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 0 10rem;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;
  align-items: flex-start;
`;

const TopRowInfo = styled.div``;

const StyledTitle = styled(Title)`
  color: ${colors.light};
`;

const StyledBodyText = styled(BodyText)`
  color: ${colors.light};
  margin-top: 5rem;
`;

const Logo = styled.img`
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;

const InfoRow = styled.div`
  display: flex;
  margin-top: 65rem;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`;

const InfoCol = styled(BodyText)`
  width: 30%;
  a {
    font-size: inherit;
    font-family: inherit;
    color: ${colors.light};
    font-weight: inherit;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 50rem;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 10%;
  flex-wrap: nowrap;
  @media (max-width: ${mediaQuery.tablet}) {
    /* justify-content: flex-start; */
    width: 100%;
  }
`;

const SocialLink = styled.a`
  margin: 0 10rem;
  display: inline;
  img {
  }
`;

const BottomRow = styled.div`
  display: flex;
  margin-top: 85rem;
  justify-content: space-between;
  p {
    font-size: ${typographySizes.xs}rem;
    color: ${colors.light};
    font-weight: 600;
  }
  a {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <Wrapper>
        <img
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
          src={sygnet}
        />
        <TopRow>
          <TopRowInfo>
            <StyledTitle
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration={500}
            >
              Do zobaczenia!
            </StyledTitle>
            <StyledBodyText
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration={500}
            >
              Jesteśmy do Twojej dyspozycji. <br /> Chcesz zarezerwować stolik
              lub masz pytania? <br /> Skontaktuj się z nami.
            </StyledBodyText>
          </TopRowInfo>
          <Logo
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
            src={logo}
          />
        </TopRow>
        <InfoRow>
          <InfoCol
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Zadzwoń lub napisz: <br />
            <br />
            <a
              href="tel:+48 577 185 265
"
            >
              +48 577 185 265
            </a>
            <br />
            <a href="mailto:zjedz@garazowa5a.pl">zjedz@garazowa5a.pl</a>
          </InfoCol>
          <InfoCol
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Restauracja Garażowa 5a
            <br />
            <br />
            <a href="https://goo.gl/maps/kx1a4dRscCtEwcum8">
              ul. garażowa 5a <br />
              02-651 Warszawa
            </a>
          </InfoCol>
          <InfoCol
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Niedziela-czwartek: <br /> 12:00–21:30 <br />
            <br />
            Piątek-sobota:
            <br />
            12:00–23:00
            <br />
          </InfoCol>
          <SocialWrapper
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            <SocialLink
              href="https://www.instagram.com/garazowa_5a/"
              target="_blank"
            >
              <img src={instagram} />
            </SocialLink>
            <SocialLink
              href="https://www.facebook.com/Garazowa5a"
              target="_blank"
            >
              <img src={fb} />
            </SocialLink>
          </SocialWrapper>
        </InfoRow>
        <BottomRow
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration={500}
        >
          <p>
            Zaprojektowane i wdrożone przez:{" "}
            <a href="https://we3studio.pl/" target="_blank">
              we3studio{" "}
            </a>{" "}
          </p>
          <p>Garażowa5a Copyright © {new Date().getFullYear()} </p>
        </BottomRow>
      </Wrapper>
    </FooterWrapper>
  );
};

export default Footer;
