import { createGlobalStyle } from "styled-components";
import colors from "src/assets/styles/colors.js";

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;

        font-family: 'Montserrat', sans-serif;
        color: ${colors.black};
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-weight: lighter;
        font-size: 1px;
    }
    ::selection {
        color: ${colors.dark};
        background: ${colors.light};
;
}


span {
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;

  }
a{
    text-decoration: none;
}
 body{
     overflow-x: hidden;
 }
 strong {

    }

    button{
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        color: inherit;
        font: inherit;
        line-height: normal;
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        -webkit-appearance: none;
    }
    button:focus {
        outline: none;
        box-shadow: none;
        }
`;

export default GlobalStyle;
