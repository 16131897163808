import React from "react";

import styled, { keyframes } from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery";
import logoSvg from "src/assets/images/svg/garazowa-logo.svg";
import circle from "src/assets/images/svg/features-circle.svg";
import Title from "src/components/Texts/Title.js";
import BodyText from "src/components/Texts/BodyText.js";
import HoverIlustation from "src/components/About/HoverIlustation.js";

const Wrapper = styled.section`
  width: calc(100% + ${distances.pageMargin}rem);
  display: flex;

  background-color: ${colors.dark};
  padding: 50rem 0;
  position: relative;

  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding: 40rem 10rem;
    width: calc(100% - 20rem);
  }
`;

const Col = styled.div`
  width: calc(43.5% - 50rem);
  padding-left: 50rem;
  display: flex;
  align-items: center;
  img {
    width: 20%;
    max-width: 80rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    padding-left: 0rem;
    margin-bottom: 40rem;
    :last-of-type {
      margin-bottom: 0rem;
    }
  }
`;

const TextCol = styled.div`
  width: calc(80% - 30rem);
  margin-left: 30rem;
  /* margin-top: 50rem; */
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 0rem;
  }
`;

const StyledTitle = styled(Title)`
  color: ${colors.light};
`;

const StyledText = styled(BodyText)`
  margin-bottom: 50rem;
  :last-of-type {
    margin-bottom: 0rem;
  }
`;

const Lunch = () => {
  return (
    <Wrapper>
      <Col>
        <img src={circle} />
        <TextCol>
          <StyledTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Lokalni <br /> dostawcy
          </StyledTitle>
          <StyledText
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Korzystamy z sezonowych produktów od lokalnych dostawców, tak więc
            menu zmienia się co kilka tygodni. Do tego domowe wypieki, selekcja
            win i mocnych alkoholi.
          </StyledText>
          <StyledTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Sezonowa <br /> kuchnia
          </StyledTitle>
          <StyledText
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Korzystamy z sezonowych produktów od lokalnych dostawców, tak więc
            menu zmienia się co kilka tygodni. Do tego domowe wypieki, selekcja
            win i mocnych alkoholi.
          </StyledText>
        </TextCol>
      </Col>
      <Col>
        <img src={circle} />
        <TextCol>
          <StyledTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            BABCIA <br /> HALINKA
          </StyledTitle>
          <StyledText
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Na miejscu już wdzięczą się do Was wspaniałe desery babci Halinki. I
            nie myślcie, że jest to chwyt marketingowy! To babcia Markusa, we
            własnej osobie, która dla nas jest mistrzynią wypieków!
          </StyledText>
          <StyledTitle
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            PIESKI <br /> MILE WIDZIANE
          </StyledTitle>
          <StyledText
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration={500}
          >
            Nasz zespół nie byłby kompletny bez „imię”, z którą codziennie
            wspólnie tworzymy dla Was menu lunchowe.
          </StyledText>
        </TextCol>
      </Col>
    </Wrapper>
  );
};

export default Lunch;
