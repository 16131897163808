import * as React from "react";

import GlobalStyle from "src/assets/styles/globalStyles.js";
import styled from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";

import Header from "src/components/Header/Header.js";
import About from "src/components/About/About.js";
import Menu from "src/components/Menu/Menu.js";
import Wine from "src/components/Wine/Wine.js";
import Order from "src/components/Order/Order.js";
import Features from "src/components/Features/Features.js";
import Lunch from "src/components/Lunch/Lunch.js";
import Events from "src/components/Events/Events.js";
import Takeaway from "src/components/Takeaway/Takeaway.js";
import Localization from "src/components/Localization/Localization.js";
import BigText from "src/components/BigText/BigText.js";
import Footer from "src/components/Footer/Footer.js";

import fbImg from "src/assets/images/image/fb.png";
import favicon from "src/assets/images/svg/favicon.svg";
import { Helmet } from "react-helmet";
import Cookies from "src/components/Cookies.js/Cookies.js";

const Wrapper = styled.div`
  padding: 0 ${distances.pageMargin}rem;
  background-color: ${colors.green};
  @media (max-width: ${mediaQuery.laptop}) {
    padding: 0 ${distances.pageMargin / 2}rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 0 10rem;
  }
`;

const IndexPage = () => {
  return (
    <main>
      <Helmet
        htmlAttributes={{ lang: "pl" }}
        titleTemplate={`Garażowa 5A  • przyjaciele • Wino • Jedzenie •`}
        title={`Garażowa 5A • przyjaciele • Wino • Jedzenie •`}
      >
        <link rel="icon" type="image/svg+xml" href={favicon} />
        <meta
          name="description"
          content="Garażowa 5A to klimatyczna restauracja na Mokotowie, wyróżniająca się wyśmienitą kuchnią autorską oraz selekcją win.
          Menu zmienia się co kilka miesięcy, aby w największym stopniu wykorzystać sezonowe lokalne produkty."
        />
        <meta name="image" content="../static/fb.jpeg" />
        <meta property="og:image" content={fbImg} />
        <meta property="og:image:secure_url" content={fbImg} />
        <meta property="og:url" content="http://pinkvegan.pl/" />
        <meta property="og:title" content="Garażowa 5A " />

        <meta
          property="og:description"
          content="• przyjaciele • Wino • Jedzenie •"
        />
      </Helmet>
      <Cookies />
      <GlobalStyle />
      <Wrapper>
        <Header />
        <About />
        <Menu />
        <Wine />
        <Order />
        <Features />
        <Lunch />
        <Events />
        <Takeaway />
        <Localization />
        <BigText />
      </Wrapper>
      <Footer />
    </main>
  );
};

export default IndexPage;
